@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-Medium.woff2') format('woff2'),
    url('./silka/Silka-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-Bold.woff2') format('woff2'),
    url('./silka/Silka-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-RegularItalic.woff2') format('woff2'),
    url('./silka/Silka-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka Extra';
  src: url('./silka/Silka-ExtraLight.woff2') format('woff2'),
    url('./silka/Silka-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-Regular.woff2') format('woff2'),
    url('./silka/Silka-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-SemiBold.woff2') format('woff2'),
    url('./silka/Silka-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-LightItalic.woff2') format('woff2'),
    url('./silka/Silka-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./silka/Silka-Light.woff2') format('woff2'),
    url('./silka/Silka-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SilkaMono';
  src: url('./silkamono/SilkaMono-Regular.woff2') format('woff2'),
    url('./silkamono/SilkaMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SilkaMono';
  src: url('./silkamono/SilkaMono-Light.woff2') format('woff2'),
    url('./silkamono/SilkaMono-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: 'vFraunces';
    src: url('./fraunces/Fraunces--latin_basic.woff2') format('woff2'); 
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'vFraunces';
    src: url('./fraunces/Fraunces--latin_basic.woff2') format('woff2'); 
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
  }
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: 'vFraunces';
    src: url('./fraunces/Fraunces-soft-wonk-opsz-wght.woff2') format('woff2');
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'vFraunces';
    src: url('./fraunces/Fraunces-italic-soft-wonk-opsz-wght.woff2') format('woff2');
    font-weight: 100 900;
    font-style: italic;
    font-display: swap;
  }
}

